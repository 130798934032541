*{
    font-family:'Segoe UI';
}
/* header css */
.header-section{
    background-color: #B42025;
    padding-top:15px;
    padding-bottom:13px;
}
.header-text{
    font-size:20px;
    color:#fff;
    font-weight: 500;
    border-right:1px solid #D6494E;
    padding-left:16px;
    padding-right:16px;
    cursor: pointer;
}
.header-text.active {
    cursor: default;
    font-weight: 800;
}
.no-brd{
    border-right:none;
    border-left:1px solid #D6494E;
    padding-left:20px;
}
.logo{
    width:125px;
    height:38px;
    margin-right:28px;
    margin-left:20px;
}

/* search screen css */
.search-section{
    padding-top:21px;
    background: white;
}
.inner-wrapper{
    width: 80%;
    margin: auto;
    padding-bottom:7px;
    position: relative;
}
.search-box{
    border:1.5px solid #989FAA;
    border-radius:8px;
    padding:7px 14px;
    width:260px;
    padding-right: 37px;
}
.search-box::placeholder{
    color:#6F7782;
    font-size: 16px;
}
.search-outer{
    position: relative;
}
img.search-icon {
    position: absolute;
    right: 6%;
    top: 38%;
    cursor:pointer;
}
.space-below{
    margin-bottom:31px;
}
.mr-140{
    margin-right:140px;
}
.sr{
    border-right:none;
    cursor: pointer;
}
.fetch-link{
    color:#B42025;
    font-size:16px;
    font-weight: 500;
    padding-left:11px;
    cursor: pointer;
}
.clear-btn {
    cursor: pointer;
}
.btns{
    margin-left:20px;
    font-size:16px;
    color:#989FAA;
    font-weight: 600;
}
.btns img{
 margin-right:8px;
}
.gn-btn{
    background: #B42025;
    border-radius: 8px;
    padding: 8px 16px;
    color:white;
    cursor: pointer;
}
.ex-btn{
    border: 1px solid #D0D6DF;
    border-radius: 8px;
    padding: 8px 16px;
}
.toggle-filter{
    border-radius: 20px;
    color:#B42025;
    font-size:16px;
    width:fit-content;
    position: absolute;
    left:0%;
    right:0%;
    margin:auto;
    text-align: center;
    box-shadow: 0px 0px 6px #00000029;
    z-index: 99;
    background: #fff;
    bottom:-7%;
    padding:6px 20px;
    cursor: pointer;
}
.toggle-filter-show{
    border-radius: 20px;
    color:#B42025;
    font-size:16px;
    width:fit-content;
    position: absolute;
    left:0%;
    right:0%;
    margin:auto;
    text-align: center;
    box-shadow: 0px 0px 6px #00000029;
    z-index: 99;
    background: #fff;
    bottom:-37%;
    padding:6px 20px;
    cursor: pointer;
}
.item-display{
    background:#F2F4F7;
    text-align: center;
    padding-top:150px;
    padding-bottom:150px;
    height: 60vh;
}
.no-data {
    color: #131313;
    font-size: 16px;
    margin-bottom:5px;
}
.no-data.error {
    color: red;
    font-size: 16px;
    margin-bottom:5px;
}
.info-text {
    color: #989FAA;
    font-size: 14px;
}
.filter-arrow{
    margin-left:9px;
    width: 14px;
}


/* schedulereport popup css */
.popup-screen{
    background: rgb(208, 214, 223,0.8);
}
.overlay {
    background: rgba(208,214,223,0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
}
.overlay-schedule-new {
    background: rgba(208,214,223,0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.schedulereport-popup {
    width: 535px;
    background: #fff;
    position: absolute;
    left: 0%;
    right: 0%;
    top:15%;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 999;
}
.popup-header{
    border-bottom:1px solid #D0D6DF;
    padding:20px 40px 16px 20px;
}
.popup-heading {
    color: #131313;
    font-size: 18px;
    font-weight: 500;
}
.popup-close {
    cursor: pointer;
}
.popup-content{
    padding-left:36px;
    padding-right:42px;
    overflow-y: auto;
    height: 450px;
}
.popup-content-sch{
    padding-left:36px;
    padding-right:42px;
    padding-top:30px;
}
.popup-content-del{
    padding-left:36px;
    padding-right:42px;
    overflow-y: auto;
}
.button-primary {
    border-radius: 8px;
    background: #B42025;
    color: #fff;
    padding: 8px 16px;
    margin-left: 20px;
    cursor: pointer;
}
.cancel-btn {
    color: #B42025;
    font-weight: 500;
    cursor: pointer;
}
.popup-footer{
    padding: 21px 36px 28px 40px;
}
.load-icon{
    margin-right:8px;
}
.ctn{
    padding:17px 0px;
    border-bottom:1px solid #D0D6DF;
}
input.popup-radio {
    margin-right: 30px;
}
.report-name{
    color:#131313;
    font-size: 14px;;
}

input[type="radio"] {
    accent-color: #B42025; 
    width: 16px;
    height: 16px; 
}

/* schedule report table css */
.screport-btn{
    border:2px solid #B42025;
    background-color: #fff;
    border-radius: 8px;
    margin-top:30px;
    margin-bottom:25px;
    padding:10px 16px;
    margin-left:auto;
    margin-right: 154px;
  }
  .plus-icon{
    margin-right:5px;
  }
  .screport-btn span{
    font-weight: 500;
  }

.report-table{
    width:100%;
    background: white;
}
.report-th th:first-child, .report-tbody tr td:first-child{
    padding-left:82px;
}
  .report-table th{
    background-color: #F9FAFB;
    padding-top: 26px;
    padding-bottom: 28px;
    text-align: left;
    inline-size: 300px;
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: initial;
  }
  .report-table tr td{
    padding-top: 28px;
    padding-bottom: 28px;
    border-top:1px solid #EBEEF2;
    border-bottom:1px solid #EBEEF2;
    color:#737373;
    font-size:14px;
    font-family: 'Roboto';
    text-align: left;
  }
  img.arrows {
    margin-left: 4px;
}
td.action-td {
    color: #131313 !important;
    font-weight: 500;
}
.edit-icon{
    border-left:1px solid #D0D6DF;
    border-right:1px solid #D0D6DF;
    padding-left:16px;
    padding-right:16px;
    cursor: pointer;
}
img.delete-icon {
    margin-left: 18px;
    cursor: pointer;
}
.mr-18{
 margin-right:18px;
 cursor: pointer;
}

.container-outer { 
    overflow-x: auto; 
    width: 95%; 
    height: auto; 
    position: absolute;
    top: 2%;
    left: 1%;
    line-height: 35px;
}
.container-inner { 
    width: 3000px; 
}

.chips span {
    background: #d3d3d391;
    margin-right: 7px;
    padding: 3px 12px;
    border-radius: 25px;
}

.container-outer-sch { 
    overflow-x: auto; 
    width: 88%; 
    height: auto; 
    position: absolute;
    top: 2%;
    left: 5%;
    line-height: 35px;
}
.container-inner-sch { 
    width: 2600px; 
}

.chips-sch span {
    background: #d3d3d391;
    margin-right: 7px;
    padding: 3px 12px;
    border-radius: 25px;
}


/* schedule report popup 2 css */
.schedulereport-table {
    padding-top: 1px;
    background: white;
    padding-bottom: 14px;
}
.schedulereport-popup2 .popup-footer{
 border-top: 1px solid #D0D6DF;
}
.schedulereport-popup2{
        width: 95%;
        background: #fff;
        position: absolute;
        left: 0%;
        right: 0%;
        top:20%;
        margin: auto;
        border-radius: 8px;
        box-shadow: 0px 3px 6px #00000029;
}
.Note-text{
    color:#131313;
    font-size:16px;
    font-weight: 500;
}
.schedulereport-popup2 .popup-content, .schedulereport-popup2 .popup-header {
  padding-left:52px;
  padding-right:42px;
}
.schedulereport-popup2 .popup-footer{
    margin-left:0px;
    margin-right:42px;    
}
.note-info{
    color:#131313;
    font-size:16px;
}
.schedulereport-popup2 .popup-content{
    padding-top:30px;
}
.schedulereport-popup2 .first-field{
    width:48% !important;
}
.schedulereport-popup2 .search-box{
    width:100%;
}

.schedulereport-popup2 .search-outer{
    width:22%;
}
.last-field{
    width:74% !important;
}
.search-outer label{
    position: absolute;
    left: 3%;
    top: -25%;
    background: #fff;
    padding-left: 7px;
    padding-right: 7px;
    color:#131313;
    font-size:12px;
    font-weight: 500;
}
.search-outer.first-field .search-icon{
    right:3%;
}
.select{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
img.down-arrow {
    position: absolute;
    right: 6%;
    top: 42%;
}
/* view report css */
.searchcourse-input{
    position: relative;
}
.searchcourse-input .search-icon{
    right:1.5%;
}
.view-rpt-btn{
    width: 144px;
    margin: auto;
    margin-top:18px;
}
.searchcourse-input input::placeholder{
 font-family: 'roboto';
 color:#6F7782 !important;
}
.view-report input::placeholder{
    color:#131313;
    font-size:16px;
}

/* search dropdown css */
.searchcourse-input .search-icon{
    top: 36.8%;
}
.search-dropdown{
    display: flex;
    justify-content: space-between;
    border:1px solid #989FAA;
    border-radius:0px 0px 8px 8px;
    padding: 15px;
    position: absolute;
    z-index: 9999;
    width: 100%;
    background: #fff;
    top: 90%;
}

.search-dropdown span{
 font-size:15px;
 color:#131313;
 font-weight:500;
 margin-left:12px;
}
.search-dropdown input{
    border:1px solid #707070;
    width:20px;
    height:20px;
    border-radius:0px !important;
}
.field-wrp{
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid #d3d3d387;
}
.fields-outer{
    width:30%;
}
.fields-outer .field-wrp:last-child{
    border-bottom:0px;
}
.search-box-sch{
    border:1.5px solid #989FAA;
    border-radius:8px;
    padding:7px 14px;
    width:260px;
    padding-right: 37px;
}
.search-outer.first-field {
    width:96% !important;
}
.search-outer.first-field .search-box-sch{
    width:100%;
}
.inner-wrapper.sch{
    width:50%;
}
.inner-wrapper.sch.right{
    display: flex;
    justify-content: end;
}
.popup-heading-message {
    margin-top: 20px;
}
.search-dropdown-sch{
    display: flex;
    justify-content: space-between;
    border:1px solid #989FAA;
    border-radius:0px 0px 8px 8px;
    padding: 15px;
    position: absolute;
    z-index: 9999;
    width: 96%;
    background: #fff;
    top: 75%;
}
.search-dropdown-sch input{
    border:1px solid #707070;
    width:20px;
    height:20px;
    border-radius:0px !important;
}
.search-dropdown-sch span{
    font-size:15px;
    color:#131313;
    font-weight:500;
    margin-left:12px;
}
.error-wrapper{
    width: 100%;
    text-align: center;
    position: absolute;
    margin-top: 16%;
}
.error-content {
    text-align: center;
    color:#fff;
    margin-top: 40px;
    font-size: 18px;
}
.error-msg {
    font-size: 12px;
    opacity: 0.8;
}

/* griddata screen css  */
.grid-data{
    padding-top:60px;
}
.data-btn{
  border:2px solid #B42025;
  color:#000;
  cursor: pointer;
}
.btn-disabled {
    background: grey;
    pointer-events: none;
}
.grid-data .report-th th:first-child, .report-tbody tr td:first-child{
  padding-left:30px;
}
.th {
    cursor: pointer;
}
.table-wrapper{
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
}
.grid-data .report-table{
    width: max-content;
    background-color: #fff;
    overflow-x: auto;
}
.grid-data .arrows{
    margin-left:5px;
    cursor: pointer;
}
.grid-data .user-icon{
  margin-right:10px;
}
.grid-pagination{
    background:#F2F4F7;
    padding:20px 100px 20px 20px;
}
.page-left-arrow{
    width: 12px;
    position: relative;
    top: -1px;
}
.page-right-arrow{
    width: 12px;
    position: relative;
    left: 4px;
    top: 1px;
  }
  .page-nos{
    margin-left:20px;
    margin-right:20px;
    overflow-x: auto;
    max-width: 500px;
  }
  .page-nos span{
    padding:0px 8px;
    cursor: pointer;
  }
  .prev, .next{
    color:#C2BDBD;
    font-size:13px;
    cursor: pointer;
  }
  .current{
   color:#000;
  }
  .status{
    color:#fff;
    border-radius:10px;
    padding-left:8px;
    padding-right:8px;
    padding-top: 2px;
    padding-bottom: 4.5px;
    font-size: 12px;
  }
  .completed{
    background-color: #4F8B31;
  }
  .enrolled{
    background-color: #5594CA;
  }
  .overdue{
    background-color: #F6272E;
  }
  .notenrolled{
    background-color: grey;
  }
  .status-info{
    margin-top:12px;
    font-size:12px;
  }
  .date{
    color:#404040;
    font-weight: 500;
  }
  .due-date{
    color:#F6272E;
  }
  .p-10{
    padding-left:10px;
    padding-right:10px;
  }

 .table-wrapper::-webkit-scrollbar{
    height: 5px;
    width: 5px;
    background: #D0D6DF;
}
.table-wrapper::-webkit-scrollbar-thumb:horizontal{
    background: #131313;
}

.search-course-fields {
    display: none;
}
.chips-mui {
    margin: 2px;
}


.autocomplete-container {
    position: relative;
    width: 300px;
  }
  
  .suggestions-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    z-index: 999;
    background-color: #fff;
    overflow-y: auto;
    height: 200px;
  }
  
  .suggestion {
    padding: 8px;
    cursor: pointer;
    border-top: 1px solid #ccc;
  }
.validation-error-message {
    color: red;
    margin-bottom: 5px;
}