*{
    font-family:'Segoe UI';
}
@font-face {
    font-family: 'Segoe UI';
    src: local('Segoe UI'), url(../public/Segoe\ UI.ttf) format('opentype');
}
body{
    background: #B42025 !important;
}

@media screen and (min-width:1200px){
    .course-screen{
     display: none;
    }
    .desktop-hide{
     display: none;
    }
    .points-bar-desk{
    }
    .scanner-screen .top-section{
      padding-top:0px !important;
      padding-bottom:0px !important;
    }
    .scanner-wrapper {
        position: absolute;
        left: 0%;
        right: 0%;
        top: 24%;
    }
    .left-panel {
        width: 25%;
    }
    .right-panel {
        width: 75%;
        position: relative;
    }
    .profile-section .user-name{
        color:#fff;
    }
    .st-cd{
        font-size: 16px;
    }
    img.user-icon {
        width: 20px;
        height: 20px;
        position: relative;
        top: 9px;
    }
    .profile-section {
        margin-left: 60px;
    }
    .user-icon{
        margin-bottom:20px;
    }
    .space-below{
        margin-bottom:30px;
    }
    .st-code{
        margin-bottom:30px;
    }
    .code{
        margin-left:0px !important;
    }
    .points-bar-desk{
        margin-bottom:58px;
        padding-top: 28px;
        padding-left: 60px;
    }
    .point-text{
      color:#F5A9AB;
      font-size:19px;
     }
     .Points-earned{
        font-size:33px;
        color:#fff;
     }
     .coin-icon{
        width:140px;
     }
     .points-wrp {
        line-height: 28px;
        position: relative;
        left: -17px;
    }
    .scanner-details{
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
    .right-panel::after {
        height: 22px;
        content: '';
        position: absolute;
        left: 0%;
        top: 0%;
        width: 24px;
        background: #A71217;
        z-index: -9;
    }
     
}
@media screen and (max-width:1199px){
    .mobile-hide{
      display:none;
    }
}
@media screen and (min-width:1025px){
    .course-screen{
      display:none;
    }
}
@media screen and (max-width:1024px){
    .scanner-wrapper {
        position: absolute;
        top: 21%;
        left: 0%;
        right: 0%;
    }
    .course-details {
        background: linear-gradient(180deg, #E5E8F0, #F3F6FF);
        border-radius: 27px;
        padding:27px 35px 17px 30px;
    }
    .scanner-details {
        padding:27px 35px 17px 30px;
        height: 100%;
    }
    .heading{
        font-size:16px;
        color:#16152D;
        font-weight:600;
    }
    .box {
        border-radius: 23px;
        background: #fff;
        padding: 14px 14px 14px 14px;
        border-top: 2px solid #B42025;
    }
    input.search-box{
        width:100%;
        background: #fff;
        border: 1px solid #7070704D;
        border-radius: 19px;
        padding:4px 36px 6px 19px;
    }
    .search-box:focus-visible{
      outline:none;
    }
    input.search-box::placeholder{
      color:#9C9C9E;
      font-size:14px;
    }
    .search-bar {
        position: relative;
    }
    img.search-icon {
        position: absolute;
        right: 4%;
        top: 32%;
    }
    .img-wrp {
        background: #B42025;
        width: fit-content;
        padding: 8px;
        border-radius:12px;
        height: fit-content;
        margin-right:13px;
    }
    .course-title {
        font-size: 15px;
        color: #131313;
        font-weight: 600;
    }
    .course-status-completed {
        background: #4F8B31;
        border-radius: 18px;
        color: #fff;
        width: fit-content;
        padding: 2px 10px 2px 6px;
        font-size:12px;
    }
    .course-status-inprogress {
        background: #EB7D00;
        border-radius: 18px;
        color: #fff;
        width: fit-content;
        padding: 2px 10px 2px 6px;
        font-size:12px;
    }
    .course-status-enrolled {
        background: #5594CA;
        border-radius: 18px;
        color: #fff;
        width: fit-content;
        padding: 2px 10px 2px 6px;
        font-size:12px;
    }
    span.date {
        color: #6E6E6E;
        font-size: 12px;
        font-weight: 500;
        padding-left:7px;
        opacity: 0.8;
    }
    .dt{
      font-size:12px;
      color:#404040;
      font-weight: 600;
      padding-left:4px;
    }
    .in-progress{
        background-color: #EB7D00;
    }
    .enrolled{
        background-color: #5594CA;    
    }
    .points-bar {
        background: #A71217;
        padding:8px 0px 0px;
        margin-bottom: 14px;
    }
    .point-text {
        color: #F5A9AB;
        font-size: 12px;
    }
    .Points-earned {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
    }
    img.user-icon {
        width: 65px;
        height: 65px;
    }
    .profile-section {
        margin-left: 30px;
        margin-bottom:13px;
    }
    .right-block {
        border-left: 1px solid rgb(245, 169, 171, 0.5);
        padding-left: 10px;
    }
    .st-cd{
        font-size: 14px;
    }
    .divider {
        width: 32px;
        height: 1px;
        background: rgb(245, 169, 171, 0.5);
        margin-top: 5px;
    }
    .points-wrp {
        position: relative;
        left: -23px;
    }
    body.error-outer {
        position: relative;
    }
    .error-wrapper{
        width: 100%;
        text-align: center;
        position: absolute;
        margin-top: 16%;
    }
    .error-content {
        text-align: center;
        color:#fff;
        margin-top: 40px;
        font-size: 18px;
    }
    .error-msg {
        font-size: 12px;
        opacity: 0.8;
    }
    .play-icon{
        margin-right:5px;
        height: 9px;
        width: 9px;
    }
}
.scanner-text{
    color:#131313;
    font-size:17px;
    font-weight: 600;
    line-height: 23px;
    font-family: 'Segoe UI';
}
.scanner-details{
    background: linear-gradient(180deg, #E5E8F0, #F3F6FF);
    border-radius: 27px;
    padding:27px 35px 17px 30px;
    height: 550px;
    position: relative;
}
.scanner-code{
    margin-bottom:30px;
}
.position {
    font-size: 14px;
    color: #F5A9AB;
}
.st-cd{
    color:#fff;
    opacity: 0.8;
}
.code{
    color:#fff;
    font-size: 16px;
    font-weight: 600;
    margin-left:5px;
}
.user-name, .prr-code {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
.ul{
    padding-left: 0rem;
}
.down-btn {
    border:1px solid #B42025;
    border-radius: 8px;
    background-color: #fff;
    padding:10px 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}
.download-icon {
    margin-right:15px;
}
.download-btn-div {
    margin-top:15px;
}